import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-wrapper-background',
  templateUrl: './wrapper-background.component.html',
  styleUrls: ['./wrapper-background.component.sass']
})
export class WrapperBackgroundComponent implements OnInit {

  currentYear: string = new Date().getFullYear().toString();

  constructor(
    public _navigation: NavigationService,
  ) {}

  ngOnInit(): void {
  }

}
