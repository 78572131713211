import { DOCUMENT, Location } from '@angular/common';
import {
  EventEmitter,
  Inject,
  Injectable,
  OnInit,
  Output,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, connect, filter } from 'rxjs';
import { UserGroupModel } from '../models/user-group.model';
import { AuthenticationService } from './authentication.service';
import { FileActionService } from './file-action.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private location = new BehaviorSubject('report');
  private accountView = new BehaviorSubject<boolean>(false);
  private searchView = new BehaviorSubject<boolean>(false);
  lastKnownLocation: string = 'report';
  location$ = this.location.asObservable();
  accountView$ = this.accountView.asObservable();
  serchView$ = this.searchView.asObservable();

  isViewAccount: boolean = false;
  initLocation: string = 'report';
  path: string = this._router.url;
  localhos: string = '';
  prevLocation: string = '';

  noAccess: boolean = false;

  readonly publicDataKey = 'PUBLIC_DATA';
  readonly fileDataKey = 'FILE_DATA';
  constructor(
    private _location: Location,
    @Inject(DOCUMENT) private _document: Document,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.lastKnownLocation = localStorage.getItem('prevLocation') ?? '';
    this._router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((e: any) => {
        if (e.url == '/recover-password' || e.url.includes('share')|| this.lastKnownLocation === e.url) {
          return;
        }

        localStorage.setItem('prevLocation', this.localhos);
        this.prevLocation = this.lastKnownLocation;
        this.lastKnownLocation = e.url;

        // console.log({ prevLocation: this.prevLocation });
      });
  }

  setLocation(data: string) {
    this.lastKnownLocation = data;
    this._router.navigateByUrl(`/${data}`);
  }
  updateLocation(location: string) {
    this.initLocation = location;
    this.setLocation(location);
  }

  getCurrentPath() {
    return this._router.url;
  }

  getCurrentView() {
    const w = window.innerWidth;
    if (w > 961) {
      return 'Desktop';
    } else {
      return 'Tablet';
    }
  }

  getPreviosLocation() {
    console.log({ localhost: this.prevLocation });
    switch (this.prevLocation) {
      case '/form-viewer':
        return '/form-viewer';
      default:
        return 'Dashboard';
    }
  }

  getLastLocation() {
    console.log({ localhost: this.prevLocation });
    switch (this.prevLocation) {
      case 'report':
      case '/dashboard':
        return 'Dashboard';
      case 'inbox':
        return 'Inbox';
      case 'docs':
        return 'Documents';
      case 'listMenu':
        return 'Folders and Files';
      case 'workflow':
      case '/workflow':
        return 'Workflow';
      case 'signatures':
        return 'Signatures';
      case 'faq':
        return 'Faq';
      case 'support':
        return 'Support';
      case 'mUser':
        return 'Mange Users';
      case 'mUserGrp':
        return 'Mange Groups';
      case 'audit':
        return 'Audit Log';
      case 'reports':
        return 'Reports';
      default:
        return 'Dashboard';
    }
  }

  getLastLocationClient() {
    console.log({ localhost: this.prevLocation });
    switch (this.prevLocation) {
      case "/form-viewer":
        return "Templates"
      default:
        return 'Templates';
    }
  }

  ngOnInit() {
    // let params = new URL(this._document.location.toString()).searchParams;
    // let name = params.get('l');
    // this.initLocation = name || 'report';
    //this.updateLocation(this.initLocation);
  }

  goTologinPage() {
    this._router.navigateByUrl(`/login`);
  }

  sharedView() {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: string) => searchParams.get(prop),
    });

    return params.f ?? null;
  }

  clearParams(error: boolean = false) {
    if (error) {
      window.history.replaceState({}, document.title, '/');
    } else {
      window.history.pushState({}, document.title, '/');
    }
  }

  setPublicData(data: any) {
    localStorage.setItem(this.publicDataKey, JSON.stringify(data));
  }

  getPublicData() {
    const data = localStorage.getItem(this.publicDataKey);
    if (data) {
      localStorage.removeItem(this.publicDataKey);
      return JSON.parse(data);
    }
    return null;
  }

  hasPublicData() {
    return !!localStorage.getItem(this.publicDataKey);
  }

  hasFileData() {
    return !!localStorage.getItem(this.fileDataKey);
  }

  setFileData(data: any) {
    localStorage.setItem(this.fileDataKey, JSON.stringify(data));
  }

  getFileData() {
    const data = localStorage.getItem(this.fileDataKey);
    if (data) {
      localStorage.removeItem(this.fileDataKey);
      return JSON.parse(data);
    }
    return null;
  }

  setType(data: string) {
    localStorage.setItem('SHARED_TYPE', data);
  }

  getType() {
    const data = localStorage.getItem('SHARED_TYPE');
    if (data) {
      localStorage.removeItem('SHARED_TYPE');
      return data;
    }
    return null;
  }

  checkShareFile(dialog: MatDialog) {
    const dialogRef = dialog.open(ModalComponent, {
      width: '512px',
      data: {
        action: 'scan-qr-restrict',
      },
    });
  }

  isMac() {
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      return true;
    }

    return false;
  }

  goBack() {
    console.log({current: this._router.url, prevLocation: this.prevLocation})
    if ((this.prevLocation !== '' && this.prevLocation !== this._router.url && `/${this.prevLocation}` !== this._router.url)) {
      this.setLocation(this.prevLocation)
    } else {
      console.log('go to dashboard')
      this._router.navigateByUrl('/dashboard');
    }
  }
}
